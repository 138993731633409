import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { mainViolet, mainWhite, screen } from "../common/variables";

const Wrapper = styled.div`
  a {
    display: inline-block;
    border-radius: 4px;
    border: 2px ${mainViolet} solid;
    background: ${mainViolet};
    color: ${mainWhite};
    font-size: 0.865rem;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 25px;
    min-width: 234px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    white-space: pre;
    width: 100%;
    @media ${screen.xsmall} {
      padding: 10px 25px;
      width: auto;
    }

    @media ${screen.withCursor} {
      &:hover {
        background: none;
        color: ${mainViolet};
      }
    }
  }
`;

const StyledButton = ({ children, to, className, onClick }) => {
  return (
    <Wrapper className={className} onClick={onClick}>
      <Link to={to}>{children || "Label"}</Link>
    </Wrapper>
  );
};

export default StyledButton;
